<template>
  <ContentCard class="admin-project" v-loading="loading">
    <template #header>
      <div>
        <h2 class="primary-text">Projects</h2>
      </div>
    </template>
    <template #body>
      <el-table :data="projects">
        <el-table-column label="Company Name">
          <template #default="scope">{{
            scope.row.founder.companyName
          }}</template>
        </el-table-column>
        <el-table-column label="Created">
          <template #default="scope">
            {{ getParsedDateTime(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="Tags">
          <template #default="scope">
            <span
              v-for="(item, index) in getFounderTags(scope.row.founder)"
              :key="index"
              class="badge badge-secondary fs-6"
              style="margin-right: 0.5rem; margin-top: 0.5rem"
              >{{ item }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="Action">
          <template #default="scope">
            <el-button
              @click="viewProjectDrawer(scope.row.founder.id)"
              class="btn btn-primary btn-sm mt-2"
              size="mini"
              >View</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="mt-5"
        v-model:currentPage="offset"
        v-model:page-size="limit"
        :page-sizes="[5, 10, 50, 100]"
        small
        layout="total, sizes, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </ContentCard>
  <PublicPitchDrawer ref="publicPitchDrawer" />
</template>

<script>
import ContentCard from "../../common/components/ContentCard";
import { onMounted, ref } from "vue";
import { getPaginatedProjects } from "../../api/admin.api";
import PublicPitchDrawer from "../../founder/components/PublicPitchDrawer";
import moment from "moment";
export default {
  name: "Founders",
  components: { PublicPitchDrawer, ContentCard },
  setup() {
    const loading = ref(false);
    const offset = ref(1);
    const limit = ref(10);
    const total = ref(0);
    const publicPitchDrawer = ref(null);

    const projects = ref([]);

    const getProjects = async (offset, limit) => {
      loading.value = true;
      const {
        data: { data },
      } = await getPaginatedProjects(offset - 1, limit);
      projects.value = data.results;
      total.value = data.total;
      loading.value = false;
    };

    const viewProjectDrawer = (id) => {
      publicPitchDrawer.value.showDrawer(id);
    };

    const getParsedDateTime = (dateTime) => {
      return moment(dateTime).format("YYYY-MM-DD HH:ss");
    };

    const handleCurrentChange = (value) => {
      offset.value = value;
      getProjects(offset.value, limit.value);
    };

    const handleSizeChange = (value) => {
      offset.value = 1;
      limit.value = value;
      getProjects(offset.value, limit.value);
    };

    const getFounderTags = (founder) => {
      const tags = [];
      if (founder) {
        if (founder.founderHasBusinessModel) {
          for (const founderHasBusinessModelElement of founder.founderHasBusinessModel) {
            tags.push(founderHasBusinessModelElement.businessModel.name);
          }
        }
        if (founder.founderHasBusinessModel) {
          for (const founderHasCustomerGroup of founder.founderHasCustomerGroup) {
            tags.push(founderHasCustomerGroup.customerGroup.name);
          }
        }
        if (founder.founderHasIndustry) {
          for (const founderHasIndustry of founder.founderHasIndustry) {
            tags.push(founderHasIndustry.industry.name);
          }
        }
      }
      return tags;
    };

    onMounted(() => {
      getProjects(offset.value, limit.value);
    });
    return {
      projects,
      publicPitchDrawer,
      loading,
      offset,
      limit,
      total,
      getParsedDateTime,
      getFounderTags,
      viewProjectDrawer,
      handleCurrentChange,
      handleSizeChange,
    };
  },
};
</script>

<style scoped></style>
